<template>
  <div class="col-md-9 ms-sm-auto col-lg-10 px-md-4">
    <main>
      <div class="main_content align-items-center">
        <div class="row justify-content-between mb_40">
          <div class="row justify-content-between mb_40 updateclient">
            <div class="col-xl-12 col-xxl-6">
              <div class="heading">
                <h2>Update Client</h2>
                <h3>
                  PBE Management Dashboard / Update Client / Self-Assessment
                </h3>
              </div>
            </div>
            <div class="col-xl-12 col-xxl-6">
              <div
                class="alche_btn tab_report toggle_switch list_card_switch me-0 my-2 d-flex justify-content-sm-start justify-content-md-end"
              >
                <ul
                  id="myTab"
                  role="tablist"
                  class="nav nav-tabs list_tabs btn-reports"
                >
                  <li
                    role="presentation"
                    class="nav-item list-item"
                    v-for="(tab, index) in tabs"
                    :key="index"
                  >
                    <button
                      :id="`${tab.id}-tab`"
                      :disabled="activeTab === tab.id"
                      @click="handleTabClick(tab)"
                      :data-bs-toggle="'tab'"
                      :data-bs-target="`#${tab.id}`"
                      type="button"
                      role="tab"
                      :aria-controls="tab.id"
                      :aria-selected="activeTab === tab.id"
                      :class="[
                        'nav-link list-link',
                        { active: activeTab === tab.id },
                      ]"
                    >
                      {{ tab.label }}
                    </button>
                  </li>
                </ul>
              </div>
            </div>
          </div>
          <form
            method="post"
            @submit.prevent="updateClientSelfAssessment"
            data-vv-scope="updateClientForm"
          >
            <div class="card site_footer mb_24 editor_section">
              <div class="card_heading">
                <h4 class="heading2">Self-Assessment Summary Report Content</h4>
              </div>
              <div class="row client_accordian">
                <div class="form-group">
                  <label class="form-label">Office Equipment Heading</label>
                  <ckeditor
                    :editor="editor"
                    v-validate="'required'"
                    data-vv-as="office equipment heading"
                    v-model="client_data.office_equipment_heading"
                    :config="editorConfig"
                    name="office_equipment_heading"
                    id="office_equipment_heading"
                    @blur="
                      onLostFocusEditor(
                        'updateClientForm.office_equipment_heading'
                      )
                    "
                  ></ckeditor>
                  <small
                    class="error text-danger"
                    v-show="
                      errors.has('updateClientForm.office_equipment_heading')
                    "
                  >
                    {{
                      errors.first("updateClientForm.office_equipment_heading")
                    }}
                  </small>
                </div>
                <div class="form-group">
                  <label class="form-label">Home Equipment Heading</label>
                  <ckeditor
                    :editor="editor"
                    v-validate="'required'"
                    data-vv-as="home equipment heading"
                    v-model="client_data.home_equipment_heading"
                    :config="editorConfig"
                    name="home_equipment_heading"
                    id="home_equipment_heading"
                    @blur="
                      onLostFocusEditor(
                        'updateClientForm.home_equipment_heading'
                      )
                    "
                  ></ckeditor>
                  <small
                    class="error text-danger"
                    v-show="
                      errors.has('updateClientForm.home_equipment_heading')
                    "
                  >
                    {{
                      errors.first("updateClientForm.home_equipment_heading")
                    }}
                  </small>
                </div>
                <div class="form-group">
                  <label class="form-label">WFH Safety Message</label>
                  <ckeditor
                    :editor="editor"
                    v-validate="'required'"
                    data-vv-as="wfh saftey message"
                    v-model="client_data.wfh_saftey_message"
                    :config="editorConfig"
                    name="wfh_saftey_message"
                    id="wfh_saftey_message"
                    @blur="
                      onLostFocusEditor('updateClientForm.wfh_saftey_message')
                    "
                  ></ckeditor>
                  <small
                    class="error text-danger"
                    v-show="errors.has('updateClientForm.wfh_saftey_message')"
                  >
                    {{ errors.first("updateClientForm.wfh_saftey_message") }}
                  </small>
                </div>
                <div class="form-group">
                  <label class="form-label">Summary Report Footer</label>
                  <ckeditor
                    :editor="editor"
                    v-validate="'required'"
                    data-vv-as="summary report footer"
                    v-model="client_data.summary_report_footer"
                    :config="editorConfig"
                    name="summary_report_footer"
                    id="summary_report_footer"
                    @blur="
                      onLostFocusEditor(
                        'updateClientForm.summary_report_footer'
                      )
                    "
                  ></ckeditor>
                  <small
                    class="error text-danger"
                    v-show="
                      errors.has('updateClientForm.summary_report_footer')
                    "
                  >
                    {{ errors.first("updateClientForm.summary_report_footer") }}
                  </small>
                </div>
                <div class="row">
                  <div class="form-group">
                    <label for="" class="form-label"
                      >Select the category to display
                      <strong>"Do you need more help?"</strong> Content
                    </label>
                    <v-select
                      v-model="client_data.conditional_msg"
                      label="name"
                      name="conditional_msg"
                      :reduce="(conditionalmsg) => conditionalmsg.id"
                      :options="conditionalmsg"
                      placeholder="Select Role"
                      v-validate="'required'"
                      data-vv-as="Condition of Message"
                    />
                    <small
                      class="error text-danger"
                      v-show="errors.has('updateClientForm.conditional_msg')"
                    >
                      {{ errors.first("updateClientForm.conditional_msg") }}
                    </small>
                  </div>
                </div>
                <div class="row">
                  <div class="form-group">
                    <label for="" class="form-label"
                      >Modify <strong>"Do you need more help?"</strong> Content
                    </label>
                    <ckeditor
                      :editor="editor"
                      v-validate="'required'"
                      data-vv-as="message content"
                      v-model="client_data.conditional_msg_content"
                      :config="editorConfig"
                      name="conditional_msg_content"
                      id="conditional_msg_content"
                      @blur="
                        onLostFocusEditor(
                          'updateClientForm.conditional_msg_content'
                        )
                      "
                    ></ckeditor>
                    <small
                      class="error text-danger"
                      v-show="
                        errors.has('updateClientForm.conditional_msg_content')
                      "
                    >
                      {{
                        errors.first("updateClientForm.conditional_msg_content")
                      }}
                    </small>
                  </div>
                </div>
                <div class="row">
                  <div class="site_footer">
                    <div class="client_btn_group action_btn">
                      <div class="row mt_24 justify-content-start">
                        <div class="col-md-10 col-lg-6 col-xl-4 text-start">
                          <button type="submit" class="btn btn_primary">
                            Update
                          </button>
                          <router-link :to="{ name: 'all_clients' }">
                            <button
                              href="javascript:void(0);"
                              class="btn btn_outline ml_24"
                            >
                              Cancel
                            </button>
                          </router-link>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </form>
        </div>
      </div>
    </main>
  </div>
</template>
<script>
import ClassicEditor from "ckeditor5-build-classic-image";
import commonFunction from "../../../mixin/commonFunction";
export default {
  provide() {
    return { parentValidator: this.$validator };
  },
  name: "Client_Self_Assessment",
  mixins: [commonFunction],
  watch: {
    // Watch for route changes and update activeTab accordingly
    $route(to) {
      switch (to.name) {
        case "edit_client":
          this.activeTab = "update-client";
          break;
        case "edit_client_self_assessment":
          this.activeTab = "self-assessment";
          break;
        case "edit_client_ergo_eval":
          this.activeTab = "ergo-eval";
          break;
        case "edit_client_self_assessment_lab":
          this.activeTab = "self-assessment-lab";
          break;
        case "edit_client_ergo_eval_lab":
          this.activeTab = "ergo-eval-lab";
          break;
        default:
          this.activeTab = "update-client";
      }
    },
  },
  data() {
    return {
      activeTab: "self-assessment",
      tabs: [
        { id: "update-client", label: "Update Client" },
        { id: "self-assessment", label: "Self-Assessment" },
        { id: "ergo-eval", label: "Ergo Eval" },
        { id: "self-assessment-lab", label: "Self-Assessment - Lab" },
        { id: "ergo-eval-lab", label: "Ergo Eval - Lab" },
      ],
      summaryReportInfo: this.$store.getters["ClientsConfig/summaryReportInfo"],
      editor: ClassicEditor,
      editorData:
        "<p>Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem Ipsum has been the industry’s standard dummy text ever since the 1500s, when an unknown printer took a galley of type and scrambled it to make a type specimen book.</p>",
      editorConfig: {
        // The configuration of the editor.
        toolbar: {
          items: [
            "heading",
            "|",
            "bold",
            "italic",
            "link",
            "|",
            "undo",
            "redo",
            "alignment",
          ],
        },
        link: {
          // Automatically add target="_blank" and rel="noopener noreferrer" to all external links.
          addTargetToExternalLinks: true,
        },
      },
      conditionalmsg: [
        {
          id: 0,
          name: "All employees see this messaging",
        },
        {
          id: 1,
          name: "Only employees that report discomfort will see the messaging",
        },
        {
          id: 2,
          name: "Only employees that report moderate or severe discomfort will see the messaging",
        },
        {
          id: 3,
          name: "Only employees that report severe discomfort will see the message.",
        },
      ],
      base_url: null,
      client_data: {
        id: "",
        u_id: "",
        office_equipment_heading: null,
        home_equipment_heading: null,
        lab_equipment_heading: null,
        wfh_saftey_message: null,
        summary_report_footer: null,
        conditional_msg: 0,
        conditional_msg_content: null,
        report_type: "self-assessment",
      },
    };
  },
  beforeMount() {
    if (this.$route.params.id == undefined) {
      // Json functions are used so that original values doesnt get changed
      this.client_data.office_equipment_heading = JSON.parse(
        JSON.stringify(this.summaryReportInfo.office_equipment_heading)
      );
      this.client_data.home_equipment_heading = JSON.parse(
        JSON.stringify(this.summaryReportInfo.home_equipment_heading)
      );
      this.client_data.lab_equipment_heading = JSON.parse(
        JSON.stringify(this.summaryReportInfo.lab_equipment_heading)
      );
      this.client_data.wfh_saftey_message = JSON.parse(
        JSON.stringify(this.summaryReportInfo.wfh_saftey_message)
      );
      this.client_data.summary_report_footer = JSON.parse(
        JSON.stringify(this.summaryReportInfo.summary_report_footer)
      );
      this.client_data.conditional_msg_content = JSON.parse(
        JSON.stringify(this.summaryReportInfo.conditional_msg_content)
      );
    }
    this.getSingleClient(this.$route.params.id);
  },
  mounted() {
    this.base_url = process.env.VUE_APP_API_URL;
  },
  methods: {
    handleTabClick(tab) {
      this.$parent.$refs.fullpageloader.is_full_page_loader = true;
      this.activeTab = tab.id;

      const clientId = this.$route.params.id;
      switch (tab.id) {
        case "edit-client":
          this.$router
            .push({
              name: "edit_client",
              params: { id: clientId },
            })
            .then(() => {
              this.$parent.$refs.fullpageloader.is_full_page_loader = false;
            });
          break;
        case "ergo-eval":
          this.$router
            .push({
              name: "edit_client_ergo_eval",
              params: { id: clientId },
            })
            .then(() => {
              this.$parent.$refs.fullpageloader.is_full_page_loader = false;
            });
          break;
        case "self-assessment-lab":
          this.$router
            .push({
              name: "edit_client_self_assessment_lab",
              params: { id: clientId },
            })
            .then(() => {
              this.$parent.$refs.fullpageloader.is_full_page_loader = false;
            });
          break;
        case "ergo-eval-lab":
          this.$router
            .push({
              name: "edit_client_ergo_eval_lab",
              params: { id: clientId },
            })
            .then(() => {
              this.$parent.$refs.fullpageloader.is_full_page_loader = false;
            });
          break;
        default:
          this.$router
            .push({
              name: "edit_client",
              params: { id: clientId },
            })
            .then(() => {
              this.$parent.$refs.fullpageloader.is_full_page_loader = false;
            });
      }
    },
    onLostFocusEditor(id) {
      this.$parent.onLostFocusEditor(id);
    },
    getSingleClient(id) {
      var _this = this;
      let config = {
        method: "get",
        url: process.env.VUE_APP_API_URL + "/api/edit-client-reports/" + id,
        headers: {
          Authorization: `Bearer ${window.localStorage.getItem("userToken")}`,
        },
      };
      _this
        .axios(config)
        .then(({ data }) => {
          if (data.success) {
            var decodedData = _this.decodeAPIResponse(data.data);
            _this.client_data.id = this.$route.params.id;
            _this.client_data.u_id = this.$route.params.id;
            _this.client_data.office_equipment_heading =
              decodedData.records.office_equipment_heading != null
                ? decodedData.records.office_equipment_heading
                : JSON.parse(
                    JSON.stringify(
                      this.summaryReportInfo.office_equipment_heading
                    )
                  );

            _this.client_data.home_equipment_heading =
              decodedData.records.home_equipment_heading != null
                ? decodedData.records.home_equipment_heading
                : JSON.parse(
                    JSON.stringify(
                      this.summaryReportInfo.home_equipment_heading
                    )
                  );

            _this.client_data.lab_equipment_heading =
              decodedData.records.lab_equipment_heading != null
                ? decodedData.records.lab_equipment_heading
                : JSON.parse(
                    JSON.stringify(this.summaryReportInfo.lab_equipment_heading)
                  );

            _this.client_data.wfh_saftey_message =
              decodedData.records.wfh_saftey_message != null
                ? decodedData.records.wfh_saftey_message
                : JSON.parse(
                    JSON.stringify(this.summaryReportInfo.wfh_saftey_message)
                  );

            _this.client_data.summary_report_footer =
              decodedData.records.summary_report_footer != null
                ? decodedData.records.summary_report_footer
                : JSON.parse(
                    JSON.stringify(this.summaryReportInfo.summary_report_footer)
                  );

            _this.client_data.conditional_msg =
              decodedData.records.conditional_msg != null
                ? decodedData.records.conditional_msg
                : JSON.parse(
                    JSON.stringify(this.summaryReportInfo.conditional_msg)
                  );

            _this.client_data.conditional_msg_content =
              decodedData.records.conditional_msg_content != null
                ? decodedData.records.conditional_msg_content
                : JSON.parse(
                    JSON.stringify(
                      this.summaryReportInfo.conditional_msg_content
                    )
                  );

            _this.client_data.ergo_office_equipment_heading =
              decodedData.records.ergo_office_equipment_heading != null
                ? decodedData.records.ergo_office_equipment_heading
                : JSON.parse(
                    JSON.stringify(
                      this.summaryReportInfo.ergo_office_equipment_heading
                    )
                  );

            _this.client_data.ergo_home_equipment_heading =
              decodedData.records.ergo_home_equipment_heading != null
                ? decodedData.records.ergo_home_equipment_heading
                : JSON.parse(
                    JSON.stringify(
                      this.summaryReportInfo.ergo_home_equipment_heading
                    )
                  );

            _this.client_data.ergo_wfh_saftey_message =
              decodedData.records.ergo_wfh_saftey_message != null
                ? decodedData.records.ergo_wfh_saftey_message
                : JSON.parse(
                    JSON.stringify(
                      this.summaryReportInfo.ergo_wfh_saftey_message
                    )
                  );

            _this.client_data.ergo_summary_report_footer =
              decodedData.records.ergo_summary_report_footer != null
                ? decodedData.records.ergo_summary_report_footer
                : JSON.parse(
                    JSON.stringify(
                      this.summaryReportInfo.ergo_summary_report_footer
                    )
                  );
          } else {
            _this.$toast.error(data.message, {
              position: "top-right",
              duration: 5000,
            });
            _this.$router.push({
              name: "all_clients",
            });
          }
        })
        .catch((response) => {
          console.log("catch", response);
          _this.$router.push({
            name: "all_clients",
            params: { errorMsg: response.data.error },
          });
        });
    },
    updateClientSelfAssessment() {
      var _this = this;
      if (localStorage.getItem("userToken")) {
        _this.$validator.validateAll("updateClientForm").then((isValid) => {
          if (isValid) {
            _this.$parent.$refs.fullpageloader.is_full_page_loader = true;
            if (_this.client_data.u_id) {
              let config = {
                method: "put",
                url:
                  process.env.VUE_APP_API_URL +
                  "/api/update-client-report/" +
                  _this.client_data.u_id,
                headers: {
                  "Content-Type": "application/json",
                  Authorization: `Bearer ${window.localStorage.getItem(
                    "userToken"
                  )}`,
                },
                data: {
                  request_data: this.encodeAPIRequestWithImages(
                    _this.client_data
                  ),
                },
              };
              _this
                .axios(config)
                .then(({ data }) => {
                  _this.$parent.$refs.fullpageloader.is_full_page_loader = false;
                  _this.$toast.success(data.message, {
                    position: "top-right",
                    duration: 5000,
                  });
                })
                .catch((err) => {
                  _this.$parent.$refs.fullpageloader.is_full_page_loader = false;
                  if (err.response.status == 422) {
                    var err1 = "";
                    for (let x in err.response.data.errors) {
                      err1 += err.response.data.errors[x];
                      err1 += "<br>";
                    }
                    _this.$toast.error(err1, {
                      position: "top-right",
                      duration: 5000,
                    });
                  } else if (err.response.status == 401) {
                    _this.tokenExpired();
                  } else {
                    _this.$toast.error(err.response.data.error, {
                      position: "top-right",
                      duration: 5000,
                    });
                  }
                });
            } else {
              _this.$router.push({
                name: "404",
                params: { errorMsg: "URL Not found." },
              });
            }
          } else {
            console.log("Error In Validation", _this.errors);
            const nodeList = document.querySelectorAll(".error");
            nodeList.forEach((element) => {
              if (element.style.display != "none") {
                var acc = element.closest(".accordion-collapse");
                if (acc != null) acc.classList.add("show");
              }
            });
          }
        });
      } else {
        _this.tokenExpired();
      }
    },
  },
};
</script>
